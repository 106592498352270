import "./style.css";

const Reporties = () => {
  return (
    <div className="reporties__wrapper">
      <h2>Reporties</h2>
    </div>
  );
};

export default Reporties;
