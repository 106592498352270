import {Slider} from "@mantine/core";
import {ReactNode, useEffect} from "react";

type DataObject = {
    label: ReactNode;
    value: number;
};
type ResultsProps = {
    data: DataObject[];
    today: number;
};


function Demo({data, today}: ResultsProps) {


    useEffect(() => {
        const today_div = document.getElementById("today_div")?.parentElement;
        const markLabelDiv = today_div?.parentElement
        const sliderMark = markLabelDiv?.children?.[0];
        sliderMark?.setAttribute("style", "width: 0; height: 0; margin-top: 2px; border-top: 13px solid #FFFFFF; border-left: 13px solid transparent; border-right: 13px solid transparent; background: #1c54fc;")
    }, [data])

    return (
        <>
            {data?.every(item => !isNaN(item?.value)) &&
                <Slider
                    className="slider_top_container_for_today"
                    label={(val) => data.find((mark) => mark.value !== val)?.value}
                    // defaultValue={100}
                    value={100}
                    disabled
                    marks={data?.sort((a, b) => a?.value - b?.value)}
                    // styles={{markLabel: {display: 'none'}}}
                />}
        </>

    );

}

export default Demo;
